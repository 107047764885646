// import logo from './logo.svg';
import './App.css';
import Navbar from './components/header/Navbar';
import Footer from './components/footer/footer';
import Contact from './components/contact/contact';
import About from './components/About/about';
import Home from './components/home/home'
import Downloadapp from './components/download app/downloadapp'
import Blog from './components/blog/blog'
import { Helmet } from 'react-helmet';
// import jouls from './jouls.png';

import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
function App() {
  return (
    <>
     <Helmet>
        <meta name="description" content="EV Charging Station App | Electric Vehicle Charging | Jouls | Jouls Ecotech | Electric Vehicle Charger" />
        <meta name="keywords" content="Introducing Jouls Connect, the state-of-the-art EV charging app designed to provide a seamless user experience with its exceptional user interface. Jouls Connect offers a host of features and functionalities that make it an ideal companion for electric vehicle owners." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
    <BrowserRouter>
    <Navbar/>
    <Routes>
      {/* <a href="/home"><img src={jouls} alt="" /></a> */}
     {/*  */}
     <Route path='/' element={<Home/>}/>
     <Route path='/home' element={<Home/>}/>
     <Route path='/Downloadapp' element ={<Downloadapp/>}/>
     
     {/* <Downloadapp></Downloadapp> */}
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/about' element ={<About/>}/>
      <Route path='/Blogs' element ={<Blog/>}/>
    </Routes>
    <Footer/>
    
    </BrowserRouter>
    
    </>
  );
}

export default App;


